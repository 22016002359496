import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { parseDpeTag } from '../all.helpers';
import { DPE_ENERGY_TIMELINE_MAP } from '../enums/ademe-mappings.enum';
import {
  AirConditioningType,
  EnergyDevice,
  EnergyTag,
  EnergyTimeline,
  EnergyType,
  HousingType,
  PROPERTY_HIGH_FLOOR_INSULATION_MAP,
  PROPERTY_WALL_INSULATION_MAP,
  PropertyHighFloorInsulation,
  PropertyHighFloorType,
  PropertyJoineryQuality,
  PropertyLowFloorInsulation,
  PropertyWallInsulation,
  VentilationType,
} from '../enums/property.enums';

export interface ParsedAdemeDpeResults {
  housingType?: HousingType;
  energyTag?: EnergyTag | null;
  gasTag?: EnergyTag | null;
  energyConsumption?: number;
  gasEmission?: number;
  livingArea?: number;
  heatedArea?: number;
  cooledArea?: number;
  banId?: string;
  address?: string;
  addition?: string;
  dpeDate?: string;
  constructionYear?: number;
  mainEnergyType?: EnergyType;
  mainEnergyDevice?: EnergyDevice;
  subEnergyType?: EnergyType;
  subEnergyDevice?: EnergyDevice;
  waterEnergyType?: EnergyType;
  waterEnergyDevice?: EnergyDevice;
  airConditioningType?: AirConditioningType;
  ventilationType?: VentilationType;
  wallInsulation?: PropertyWallInsulation;
  lowFloorInsulation?: PropertyLowFloorInsulation;
  joineryQuality?: PropertyJoineryQuality;
  highFloorType?: PropertyHighFloorType;
  highFloorInsulation?: PropertyHighFloorInsulation;
  mainEnergyTimeline?: EnergyTimeline;
  subEnergyTimeline?: EnergyTimeline;
  waterEnergyTimeline?: EnergyTimeline;
}

@Injectable()
export class AdemeService {
  private _baseUrl = 'https://data.ademe.fr/data-fair/api/v1/datasets/';

  constructor(private readonly http: HttpClient) {}

  public getExistingPropertyDpe(dpeLegalId: string): Observable<ParsedAdemeDpeResults | null> {
    return this._getDpeInformation(dpeLegalId, `${this._baseUrl}dpe-v2-logements-existants`);
  }

  public getNewPropertyDpe(dpeLegalId: string): Observable<ParsedAdemeDpeResults | null> {
    return this._getDpeInformation(dpeLegalId, `${this._baseUrl}dpe-v2-logements-neufs`);
  }

  private _getDpeInformation(dpeLegalId: string, url: string): Observable<ParsedAdemeDpeResults | null> {
    return this.http
      .get<any>(`${url}/lines`, {
        params: new HttpParams().append('qs', `N°DPE:"${dpeLegalId}"`),
      })
      .pipe(
        map((info) => {
          if (info.total > 0 && info.results?.length > 0) {
            return this._parseDpeResult(info.results[0]);
          }

          return null;
        }),
      );
  }

  private _parseDpeResult(result: any): ParsedAdemeDpeResults {
    const housingType = result['Type_bâtiment'] === 'maison' ? HousingType.IndividualHouse : HousingType.Apartment;
    const energyTag = parseDpeTag(result['Etiquette_DPE']);
    const gasTag = parseDpeTag(result['Etiquette_GES']);
    const waterEnergyType = this._getWaterEnergyType(
      result['Type_installation_solaire'],
      result['Type_énergie_principale_ECS'],
    );

    const [highFloorType, highFloorInsulation] = this._getHighFloorTypeAndInsulation(result);

    return {
      housingType,
      energyTag,
      gasTag,
      energyConsumption: result['Conso_5_usages_par_m²_é_primaire'],
      gasEmission: result['Emission_GES_5_usages_par_m²'],
      livingArea: result['Surface_habitable_logement'],
      heatedArea: result['Surface_chauffée_installation_chauffage_n°1'],
      cooledArea: result['Surface_climatisée'],
      banId: result['Identifiant__BAN'],
      address:
        result['Identifiant__BAN']?.split('_').length === 3
          ? result['Adresse_(BAN)']?.trim()
          : result['Adresse_brute']?.trim(),
      addition: result["Complément_d'adresse_logement"],
      dpeDate: result['Date_établissement_DPE'],
      constructionYear: result['Année_construction'],
      mainEnergyType: this._getEnergyType(result['Type_énergie_générateur_n°1_installation_n°1']),
      mainEnergyDevice: this._getEnergyDevice(
        result['Type_installation_chauffage_n°1'],
        result['Type_générateur_n°1_installation_n°1'],
      ),
      subEnergyType: this._getEnergyType(result['Type_énergie_générateur_n°2_installation_n°1']),
      subEnergyDevice: this._getEnergyDevice(
        result['Type_installation_chauffage_n°2'],
        result['Type_générateur_n°2_installation_n°1'],
      ),
      waterEnergyType,
      waterEnergyDevice: this._getWaterEnergyDevice(
        result['Type_installation_ECS'],
        result['Type_générateur_ECS_n°1'],
        waterEnergyType,
      ),
      ventilationType: this._getVentilationType(result['Type_ventilation']),
      airConditioningType: this._getAirConditioningType(result['Type_générateur_froid']),
      wallInsulation: this._findByValueInMap(PROPERTY_WALL_INSULATION_MAP, result['Qualité_isolation_murs']),
      lowFloorInsulation: this._findByValueInMap(
        PROPERTY_WALL_INSULATION_MAP,
        result['Qualité_isolation_plancher_bas'],
      ),
      joineryQuality: this._findByValueInMap(PROPERTY_WALL_INSULATION_MAP, result['Qualité_isolation_menuiseries']),
      highFloorType: highFloorType,
      highFloorInsulation: highFloorInsulation,
      mainEnergyTimeline:
        this._mapKeyIncludedInSearchString(DPE_ENERGY_TIMELINE_MAP, result['Type_générateur_n°1_installation_n°1']) ||
        null,
      subEnergyTimeline:
        this._mapKeyIncludedInSearchString(DPE_ENERGY_TIMELINE_MAP, result['Type_générateur_n°2_installation_n°1']) ||
        null,
      waterEnergyTimeline:
        this._mapKeyIncludedInSearchString(DPE_ENERGY_TIMELINE_MAP, result['Type_générateur_ECS_n°1']) || null,
    };
  }

  private _inc(s1: string, s2: string): boolean {
    if (!s1 || !s2) {
      return false;
    }

    return s1.toLowerCase().includes(s2.toLowerCase());
  }

  private _getEnergyDevice(s1: string, s2: string): EnergyDevice | undefined {
    if (this._inc(s2, 'Convecteur électrique')) return EnergyDevice.ElectricConvectors;

    if (this._inc(s2, 'Panneau rayonnant électrique')) return EnergyDevice.RadiantPanels;

    if (this._inc(s2, 'Radiateur électrique à accumulation')) return EnergyDevice.InertiaRadiators;

    if (this._inc(s2, 'Chaudière électrique')) return EnergyDevice.ElectricBoiler;

    if (this._inc(s2, 'Plancher ou plafond rayonnant')) return EnergyDevice.RadiantFloorOrCeiling;

    if (this._inc(s2, 'PAC air/air')) return EnergyDevice.AirAirHeatPump;

    if (this._inc(s2, 'PAC air/eau')) return EnergyDevice.AirWaterHeatPump;

    if (this._inc(s2, 'PAC eau/eau')) return EnergyDevice.WaterWaterHeatPump;

    if (this._inc(s2, 'PAC géothermique')) return EnergyDevice.WaterWaterHeatPump;

    if (this._inc(s2, 'PAC eau glycolée/eau')) return EnergyDevice.WaterWaterHeatPump;

    if (this._inc(s2, 'Autres émetteurs à effet joule')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière bois bûche') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLogBoiler;

    if (this._inc(s2, 'Chaudière bois bûche') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLogBoiler;

    if (this._inc(s2, 'Chaudière bois granulés') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualGranuleBoiler;

    if (this._inc(s2, 'Chaudière bois granulés') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalGranuleBoiler;

    if (this._inc(s2, 'Chaudière bois plaquette')) return EnergyDevice.WoodPlateBoiler;

    if (this._inc(s2, 'Cuisinière')) return EnergyDevice.WoodCooker;

    if (this._inc(s2, 'Poêle bûche')) return EnergyDevice.WoodStove;

    if (this._inc(s2, 'poêle à bois bouilleur bûche')) return EnergyDevice.WoodStove;

    if (this._inc(s2, 'Poêle à granulés')) return EnergyDevice.GranuleStove;

    if (this._inc(s2, 'poêle à bois bouilleur granulés')) return EnergyDevice.GranuleStove;

    if (this._inc(s2, 'insert')) return EnergyDevice.ClosedInsetFireplace;

    if (this._inc(s2, 'Foyer fermé')) return EnergyDevice.ClosedInsetFireplace;

    if (this._inc(s2, 'autre système à combustion bois')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière fioul à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière fioul à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière fioul basse température') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière fioul basse température') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière fioul standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul classique') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul classique') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'autre système à combustion fioul')) return EnergyDevice.Other;

    if (this._inc(s2, 'Poêle fioul')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière gaz à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière gaz à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière gaz basse température') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière gaz basse température') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière gaz standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz classique') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz classique') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'autre système à combustion gaz')) return EnergyDevice.Other;

    if (this._inc(s2, 'autre système thermodynamique gaz')) return EnergyDevice.Other;

    if (this._inc(s2, 'radiateur à gaz indépendant')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière gpl/propane/butane à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    return undefined;
  }

  private _getWaterEnergyDevice(s1: string, s2: string, energyType: EnergyType | undefined): EnergyDevice | undefined {
    if (this._inc(s2, 'Ballon électrique') && energyType === EnergyType.Solar) return EnergyDevice.ElectricComplement;

    if (this._inc(s2, 'Ballon électrique')) return EnergyDevice.ElectricBalloon;

    if (this._inc(s2, 'chauffe-eau électrique instantané')) return EnergyDevice.InstantaneousWaterHeater;

    if (this._inc(s2, 'CET sur air')) return EnergyDevice.ThermodynamicBalloon;

    if (this._inc(s2, 'autre système thermodynamique électrique')) return EnergyDevice.ThermodynamicBalloon;

    if (this._inc(s2, 'PAC')) return EnergyDevice.HeatPump;

    if (this._inc(s2, 'Chaudière électrique')) return EnergyDevice.ElectricBoiler;

    if (this._inc(s2, 'Chaudière bois bûche') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLogBoiler;

    if (this._inc(s2, 'Chaudière bois bûche') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLogBoiler;

    if (this._inc(s2, 'Chaudière à granulés') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalGranuleBoiler;

    if (this._inc(s2, 'Chaudière à granulés') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualGranuleBoiler;

    if (this._inc(s2, 'Chaudière bois plaquette')) return EnergyDevice.WoodPlateBoiler;

    if (this._inc(s2, 'poêle à bois bouilleur bûche')) return EnergyDevice.LogBoilerStove;

    if (this._inc(s2, 'poêle à bois bouilleur granulés')) return EnergyDevice.GranuleBoilerStove;

    if (this._inc(s2, 'Chaudière fioul à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière fioul à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière fioul basse température') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière fioul basse température') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière fioul standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul classique') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière fioul classique') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'autre système à combustion fioul')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière gaz à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière gaz à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière gaz basse température') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière gaz basse température') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualLowTemperatureBoiler;

    if (this._inc(s2, 'Chaudière gaz standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz classique') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gaz classique') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chauffe-eau gaz')) return EnergyDevice.InstantaneousWaterHeater;

    if (this._inc(s2, 'Accumulateur gaz')) return EnergyDevice.Accumulator;

    if (this._inc(s2, 'autre système à combustion gaz')) return EnergyDevice.Other;

    if (this._inc(s2, 'Chaudière gpl/propane/butane à condensation') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalCondensingBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane à condensation') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualCondensingBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane standard') && this._inc(s1, 'installation collective'))
      return EnergyDevice.CommunalClassicBoiler;

    if (this._inc(s2, 'Chaudière gpl/propane/butane standard') && this._inc(s1, 'installation individuelle'))
      return EnergyDevice.IndividualClassicBoiler;

    if (this._inc(s2, 'Chauffe-eau gpl/propane/butane')) return EnergyDevice.InstantaneousWaterHeater;

    if (this._inc(s2, 'Accumulateur gpl/propane/butane')) return EnergyDevice.Accumulator;

    if (this._inc(s2, 'CET')) return EnergyDevice.ElectricComplement;

    if (this._inc(s2, 'gaz') && energyType === EnergyType.Solar) return EnergyDevice.GasComplement;

    if (this._inc(s2, 'gpl') && energyType === EnergyType.Solar) return EnergyDevice.GasComplement;

    return undefined;
  }

  private _getWaterEnergyType(solarType: string, s: string): EnergyType | undefined {
    if (this._inc(solarType, 'ECS solaire')) {
      return EnergyType.Solar;
    }

    return this._getEnergyType(s);
  }

  private _getEnergyType(s: string): EnergyType | undefined {
    if (this._inc(s, 'Gaz naturel')) return EnergyType.TownGas;

    if (this._inc(s, 'Électricité')) return EnergyType.Electricity;

    if (this._inc(s, 'Bois – Bûches')) return EnergyType.Wood;

    if (this._inc(s, 'Bois – Granulés (pellets) ou briquettes')) return EnergyType.Wood;

    if (this._inc(s, 'GPL')) return EnergyType.LPG;

    if (this._inc(s, 'Fioul domestique')) return EnergyType.Fuel;

    if (this._inc(s, 'Réseau de Chauffage urbain')) return EnergyType.HeatingNetwork;

    return undefined;
  }

  private _getVentilationType(s: string): VentilationType | undefined {
    if (this._inc(s, 'VMC SF') || this._inc(s, 'VMC Basse Pression')) {
      return VentilationType.VariableSimpleFlow;
    }

    if (this._inc(s, 'VMC DF') || this._inc(s, 'Puits climatique')) {
      return VentilationType.PassiveDualFlow;
    }

    if (this._inc(s, 'Ventilation mécanique') || this._inc(s, 'Ventilation hybride')) {
      return VentilationType.ConstantSimpleFlow;
    }

    if (this._inc(s, 'Ventilation naturelle') || this._inc(s, 'Ventilation par')) {
      return VentilationType.None;
    }

    return undefined;
  }

  private _getAirConditioningType(s: string): AirConditioningType | undefined {
    if (this._inc(s, 'PAC')) {
      return AirConditioningType.HeatPump;
    }

    if (this._inc(s, 'Autre')) {
      return AirConditioningType.MobileOrMonobloc;
    }

    return AirConditioningType.None;
  }

  private _getHighFloorTypeAndInsulation(result: any): any[] {
    let highFloorType = null;
    let highFloorInsulation = null;

    // get ademeData quality for enum type and link them in an object
    const propertyHighFloorTypes = {
      roofTerrace: {
        record: result['Qualité_isolation_plancher_haut_toit_terrase'],
        value: PropertyHighFloorType.RoofTerrace,
      },
      convertedAttic: {
        record: result['Qualité_isolation_plancher_haut_comble_aménagé'],
        value: PropertyHighFloorType.ConvertedAttic,
      },
      lostAttic: {
        record: result['Qualité_isolation_plancher_haut_comble_perdu'],
        value: PropertyHighFloorType.LostAttic,
      },
    };

    // filters the object where ademeRecord is not null/undefined
    const validHighFloorTypes = Object.values(propertyHighFloorTypes).filter((e) => !!e.record);

    // if there is only one element, we assign it to the property value
    if (validHighFloorTypes.length === 1) {
      highFloorType = validHighFloorTypes[0].value;
      highFloorInsulation = this._findByValueInMap(PROPERTY_HIGH_FLOOR_INSULATION_MAP, validHighFloorTypes[0].record);
    }

    // if there is more than 1 valid high floor type, we check if they are all equal, if yes, we assign it to property.highFloorInsulation
    if (validHighFloorTypes.length > 1) {
      let previousRecord: string;

      const isAllNotEqual = Object.values(propertyHighFloorTypes).some((e) => {
        if (!!previousRecord && !!e.record) {
          if (previousRecord !== e.record) {
            return true;
          }

          previousRecord = e.record;
        }

        return false;
      });

      if (!isAllNotEqual) {
        highFloorInsulation = this._findByValueInMap(PROPERTY_HIGH_FLOOR_INSULATION_MAP, validHighFloorTypes[0].record);
      }
    }

    return [highFloorType, highFloorInsulation];
  }

  private _findByValueInMap(map: Map<any, any>, toFind: any): any | null {
    if (!toFind) {
      return null;
    }

    for (const [key, value] of map) {
      if (value.toLowerCase() === toFind.toLowerCase()) {
        return key;
      }
    }
  }

  private _mapKeyIncludedInSearchString(map: Map<string, any>, searchString: string): any {
    if (!searchString) {
      return null;
    }

    for (const key of map.keys()) {
      if (searchString.includes(key)) {
        return map.get(key);
      }
    }
  }
}
