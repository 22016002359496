import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class EdApiService {
  private _baseUrl = `${environment.api.baseUrl}/properties`;

  constructor(private readonly http: HttpClient) {}

  public pingApi(): Observable<any> {
    const headers = new HttpHeaders({ 'Cache-Control': 'no-cache' });

    return this.http.get<void>(`${this._baseUrl}/ping-api`, {
      headers,
      withCredentials: true,
    });
  }

  public getBuildings(lat: number, lon: number, banId?: string): Observable<any> {
    let params = new HttpParams().append('lat', lat).append('lon', lon);

    if (banId) {
      params = params.append('banId', banId);
    }

    return this.http
      .get<any>(`${this._baseUrl}/buildings`, {
        params,
        withCredentials: true,
      })
      .pipe(
        map((info) => {
          return info;
        }),
      );
  }
}
