import { EnergyTimeline } from './property.enums';

export const DPE_ENERGY_TIMELINE_MAP = new Map<string, EnergyTimeline>([
  ['1970-1975', EnergyTimeline.Before2000],
  ['1976-1980', EnergyTimeline.Before2000],
  ['1978-1994', EnergyTimeline.Before2000],
  ['1981-1985', EnergyTimeline.Before2000],
  ['1981-1989', EnergyTimeline.Before2000],
  ['1981-1990', EnergyTimeline.Before2000],
  ['1986-1990', EnergyTimeline.Before2000],
  ['1986-2000', EnergyTimeline.Before2000],
  ['1990-2000', EnergyTimeline.Before2000],
  ['1991-2000', EnergyTimeline.Before2000],
  ['1995-2003', EnergyTimeline.Before2000],
  ['1996-2000', EnergyTimeline.Before2000],
  ['avant 1970', EnergyTimeline.Before2000],
  ['avant 1978', EnergyTimeline.Before2000],
  ['avant 1980', EnergyTimeline.Before2000],
  ['avant 1981', EnergyTimeline.Before2000],
  ['avant 1990', EnergyTimeline.Before2000],
  ['entre 1990 et 2004', EnergyTimeline.Before2000],
  ['2001-2015', EnergyTimeline.Between2001And2014],
  ['2004-2012', EnergyTimeline.Between2001And2014],
  ['2005 à 2006', EnergyTimeline.Between2001And2014],
  ['2010-2014', EnergyTimeline.Between2001And2014],
  ['entre 2008 et 2014', EnergyTimeline.Between2001And2014],
  ['2018-2019', EnergyTimeline.After2015],
  ['après 2014', EnergyTimeline.After2015],
  ['après 2015', EnergyTimeline.After2015],
  ['après 2017', EnergyTimeline.After2015],
  ['après 2019', EnergyTimeline.After2015],
  ['entre 2015 et 2016', EnergyTimeline.After2015],
  ['partir de 2015', EnergyTimeline.After2015],
  ['partir de 2018', EnergyTimeline.After2015],
  ['partir de 2020', EnergyTimeline.After2015],
]);
